import '../../node_modules/jquery/dist/jquery.min';

import 'postscribe'
import '../../node_modules/cookies-enabler'



import SimpleLightbox from "simplelightbox";

var Isotope = require('isotope-layout');
// add cellsByRow layout mode
// require('isotope-cells-by-row')


// Import all of Bootstrap's JS
import * as bootstrap from '../../node_modules/bootstrap/';

// Import Lottie player
import '@dotlottie/player-component';

import '../../node_modules/waypoints/lib/jquery.waypoints.min' 
import '../../node_modules/waypoints/lib/shortcuts/inview' 
  

import '../../node_modules/lightbox2/src/js/lightbox.js'


var waypoint = new Waypoint({
    element: document.getElementById('main'),
    handler: function(direction) {
      
    //   console.log(this.element.id + ' hit ' + direction)

      if(direction === 'down'){
        packMenu();
        
      } else {
        unpackMenu();
      }

    },
    offset:94,
  })

  function packMenu() {
    let $menu = jQuery("#mainNav"); 
    let $smallLogo = jQuery("#logo-white");

    $menu.addClass('sticky-top');
    $smallLogo.show();

    jQuery('#mainNavBar .navbar-nav').removeClass('mx-auto');
    jQuery('#mainNav .navbar-toggler').removeClass('mx-auto');

    jQuery('#mainNavBar .navbar-nav').addClass('ms-auto');
    jQuery('#mainNavBar .navbar-nav').removeClass('justify-content-center');
    jQuery('#mainNavBar .navbar-nav').addClass('justify-content-end');
  
  }

  function unpackMenu() {
    let $menu = jQuery("#mainNav"); 
    let $smallLogo = jQuery("#logo-white");

    $menu.removeClass('sticky-top');
 
    $smallLogo.hide();


    jQuery('#mainNavBar .navbar-nav').addClass('mx-auto'); 
    jQuery('#mainNav .navbar-toggler').addClass('mx-auto');

    jQuery('#mainNavBar .navbar-nav').removeClass('ms-auto');
    jQuery('#mainNavBar .navbar-nav').addClass('justify-content-center');
    jQuery('#mainNavBar .navbar-nav').removeClass('justify-content-end');
 

  }
  
let countersDiv = document.getElementById('counters');
if(countersDiv){
  var counters = new Waypoint({
    element: countersDiv,
    handler: function(direction) {
    
    jQuery('.count').each(function () {
        jQuery(this).prop('Counter',0).animate({
            Counter: jQuery(this).attr('data-namba')
        }, {
            duration: 500,
            easing: 'swing',
            step: function (now) {
                jQuery(this).text(Math.ceil(now));
            }
        });
    });

    }
  })
}


 
jQuery( function() {
    //
    jQuery('.hasanim').each(function() {
        var in_view = new Waypoint.Inview({
            element: this,
            enter: function(direction) {
            jQuery(this.element).addClass('animate');
            // console.log('Enter triggered with direction ' + direction);\$\(
            },
            exited: function(direction) {
            // jQuery(this.element).removeClass('animate');
            //console.log('Exit triggered with direction ' + direction);
            },
            offset: 100 // ADDED, NOTHING CHANGED
        });
    });

        //
    jQuery(' .hasrepeat').each(function() {
      var in_view = new Waypoint.Inview({
          element: this,
          enter: function(direction) {
          // jQuery(this.element).addClass('animate');
          // console.log('Enter triggered with direction ' + direction);\$\(
          },
          exited: function(direction) {
          jQuery(this.element).removeClass('animate');
          //console.log('Exit triggered with direction ' + direction);
          },
          offset: 100 // ADDED, NOTHING CHANGED
      });
    });

  

});


let grid = document.getElementById('grid');
if(grid){
    

  jQuery( function() { 

    // jQuery( '.grid_item' ).on('click', function(){
    //   let dateVal = new Date( jQuery(this).find('.date-fr-format').text().split("/").reverse().join("-") ).getTime() ;
    //   let str = '<p>' + dateVal + '</p>';
    //   jQuery(this).find('.date-fr-format').text(str);
    // });

    

    var docgrid = new Isotope( '#grid', {
      layoutMode: 'masonry',
      getSortData: {
        date: function( itemElem ) { // function
          var theDate = new Date( jQuery( itemElem ).find('.date-fr-format').text().split("/").reverse().join("-") );
          return  theDate.getTime();
        }
      },
      sortBy: 'date',
    });

   

    // Load filtering from hash if set
    let hash = window.location.hash;
    if(hash != ''){
      let hash_id = hash.substr(1);
      // console.log(hash_id);
      docgrid.arrange({ filter: function(itemElem){  
          return jQuery(itemElem).hasClass(hash_id);  
        },
     });
    } 
    // else {
    //   docgrid.arrange({ filter: function(itemElem){
    //     return false;
    //    },
    //  });
    // }

    // Toggle all docs with btn 
    jQuery('#docs_show_all_btn').on('click', function(e){
      e.preventDefault();
      let $target = jQuery(e.target);
      
      if($target.text() == 'Tout masquer'){
        jQuery(".filter_link").removeClass("active");
        window.location.hash = "";
        docgrid.arrange({ filter: function(itemElem){
            return false;
          },
          });
        $target.text('Tout afficher');

      } else{
        jQuery(".filter_link").removeClass("active");
        window.location.hash = "";
        docgrid.arrange({ filter: function(itemElem){
          return true;
         },
         sortBy: 'date',
       });

      

      docgrid.updateSortData();

       $target.text('Tout masquer');
      }


    });
     
       
    // Filter with nav function
    jQuery('.filters').on( 'click', '.filter_link', function(e) {

    e.preventDefault();

    var filterVal = jQuery(this).attr('data-filter');

    window.location.hash = filterVal;

    jQuery('#docs_show_all_btn').text('Tout afficher')
    jQuery(this).siblings().removeClass('active');
    jQuery( this ).addClass('active');

    // console.log('click: ', filterVal);

    // set filter for Isotope
    docgrid.arrange({ filter: function(itemElem){
      // console.log(filterVal);
      if(filterVal){
        return jQuery(itemElem).hasClass(filterVal);
      } else {
        return true;
      }

    } });

    });

    jQuery('.filters').on( 'click', '.dropdown-item', function(e) {

      e.preventDefault();
  
      var filterVal = jQuery(this).attr('data-filter');
  
      window.location.hash = jQuery(this).attr('href');
  
      jQuery(this).siblings().removeClass('active');
      jQuery( this ).addClass('active');
  
      // console.log('click: ', filterVal);
  
      // set filter for Isotope
      docgrid.arrange({ filter: function(itemElem){
        // console.log(filterVal);
        if(filterVal){
          return jQuery(itemElem).hasClass(filterVal);
        } else {
          return true;
        }
  
      } });
  
    });

  
  });


}




// bind some animations to scroll 
var offsetStart = 66  ;
var offsetEnd = 0;

window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll', ( window.pageYOffset - offsetStart ) / ( document.body.offsetHeight - offsetStart - offsetEnd - window.innerHeight ));
}, false);






